import React from 'react';
import { Button, message } from 'antd';
import axios from 'axios';

function DownloadLink(props) {
    const clickBtn = async (data) => {
        let auth = props.auth

        try {
            await axios.get(`https://lk.transcargo-group.com/basezip/hs/order/${props.dataSource.header.key}?file=get`, {
                auth,
                responseType: 'arraybuffer'
            })
                .then(({ data }) => {
                    // console.log('data ', data);
                    message.success(`Скачивание файла ${props.dataSource.header.fileName}`)
                    const link = document.createElement('a');
                    // console.log('link ', link);
                    const url = window.URL.createObjectURL(new Blob([data]))
                    // console.log('url ', url);
                    // console.log('FileName ', props.dataSource.header.fileName);
                    link.href = url;
                    link.download = props.dataSource.header.fileName;
                    link.click();
                });

        } catch (err) {
            console.error(err);
            message.error(err)
        }
    }

    return (
        props.dataSource.header.fileName ?
            (<div>
                <Button onClick={() => clickBtn(props)} type="link">{props.dataSource.header.fileName}</Button>
            </div>)
            :
            (<div>
                <Button type="link" disabled>Нет вложения</Button>
            </div>)
    )
}

export default DownloadLink