import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import axios from 'axios';

function OrderStatus(order) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = async () => {
    const auth = order.auth;
    let body = {
      code: order.number,
      vessel: order.vesselCode
    };
    const vesselCode = order.vesselCode;

    let resp = await axios.post("https://lk.transcargo-group.com/basezip/hs/orderstatus", body, { auth });
    console.log(resp)

    let newOrders = await axios.get(`https://lk.transcargo-group.com/basezip/hs/zayavki/${vesselCode}`, { auth });
    console.log('orders new', { newOrders });
    // console.log('order', order);
    console.log('neworder.data', newOrders.data);
    order.setOrders(newOrders.data);
    message.success(`Статус заявки изменен `) //${resp.data.code}
    // order.setVisible(false);
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Подтвердить получение
      </Button>
      <Modal
        title="Подтверждение получения заказа"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        maskClosable={false}
      >
        <p>Заказ получен, подтверждаю.</p>
      </Modal>
    </>
  );
}

export default OrderStatus;
