import React from 'react';
import { Button, message } from 'antd';
import axios from 'axios';

function DownloadForm(props) {
    const clickBtn = async () => {
        let auth = props.auth
        console.log(props.auth);
        console.log(props.dataSource);
        try {
            await axios.get(`https://lk.transcargo-group.com/basezip/hs/order/${props.dataSource.header.key}?form=get`, {
                auth,
                responseType: 'arraybuffer'
            })
                .then(({ data }) => {
                    // console.log('data ', data);
                    let fileName = props.dataSource.header.number.replaceAll('/', '_')
                    message.success(`Скачивание файла Заявка ${fileName}.pdf`)
                    const link = document.createElement('a');
                    // console.log('link ', link);
                    const url = window.URL.createObjectURL(new Blob([data]))
                    // console.log('url ', url);
                    // console.log('FileName ', props.dataSource.header.fileName);
                    link.href = url;
                    
                    link.download = `Заявка ${fileName}.pdf` //props.dataSource.header.fileName;
                    link.click();
                });

        } catch (err) {
            console.error(err);
            message.error(err)
        }
    }

    return (
        <div>
            <Button onClick={() => clickBtn(props)} type="link">Печатная форма</Button>
        </div>

    )
}

export default DownloadForm

