import React from 'react';
import { Button } from 'antd';
import OrderStatus from './OrderStatus';

function ConfirmReceipt(props) {
      
    return (
         props.statuskey === '1' ?
         (<div>
            <OrderStatus
          number={props.number}
          auth={props.auth}
          vesselCode={props.vesselCode}
          setOrders={(o) => props.setOrders(o)}
          setVisible={v => props.setVisible(v)}
        />
        </div>) 
         :
         (<div>
           <Button type="primary" disabled>Подтвердить получение</Button>
        </div>)
    )
}

export default ConfirmReceipt