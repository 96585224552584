import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Modal, Button, Space, Select, Radio, Typography, Divider, message, Upload, Alert } from 'antd';
import axios from 'axios';
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import ThemeContext from './context';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const units = ["шт", "кг", "см", "литр", "метр"];
let index = 8888;

function NewOrder(context) {
  const { copyOrder, setCopyOrder, setModalVisible, modalVisible, typeOrder, setTypeOrder,
    rules, setRules, setVendors, } = useContext(ThemeContext)
  const vesselCode = context.vesselCode;
  const auth = context.auth;
  const [form] = Form.useForm();
  const [name, setName] = useState(null);
  const inputRef = useRef(null);
  const [fileList, setFileList] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [errorDescription, setErrorDescription] = useState('')

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      const isPDF = file.type === "application/pdf";
      const correctFile = isPNG || isJPG || isPDF
      console.log(correctFile);
      console.log(Upload.LIST_IGNORE);
      if (!correctFile) {
        message.error(`Файл ${file.name} не загружен! Только типы файлов png, jpg, pdf могут зыть загружены`);
      }
      if (file.size > 4186400) {
        message.error(`Файл ${file.name} превышает допустимый размер в 4 мегабайта`)
        return Upload.LIST_IGNORE
      }
      if (correctFile) {
        setFileList([...fileList, file]);
      }
      return correctFile || Upload.LIST_IGNORE;
    },
    fileList,
  }
  //----------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    form.resetFields();
    setFileList([])
  }, [modalVisible])

  if (modalVisible) {
    window.onbeforeunload = function () {
      return true;
    };
  }

  const addItem = (e) => { //добавить нового производителя
    e.preventDefault();
    console.log('name', name);
    setVendors([...context.vendors, { 'key': ++index, 'name': name }]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onChangeRadioBtn = (e) => {
    form.resetFields(["catvessel"])
    settingValidRulesOrder(e.target.value)  // изм валидации формы
    setTypeOrder(e.target.value)
  };

  const settingValidRulesOrder = (type) => {
    if (type === 'spareParts') {
      setRules({
        rules1: true,
        rules2: false,
        rules3: false,
        rulesName: true,
        rulesArtic: true,
        rulesQuantity: true,
        rulesUnit: true,
        validStatusName: 'error',
        validStatusType: 'error',
        validStatusSerial: 'error',
        validStatusVendor: 'error',
        validStatusComponent: 'error',

        name: 'error',
        articlenumber: 'error',
        drawing: 'error',
        position: 'error',
        quantity: 'error',
        unit: 'error',
      })
    } else if (type === 'expendableSupply') {
      setRules({
        rules1: false,
        rules2: true,
        rules3: false,
        rulesName: true,
        rulesArtic: true,
        rulesQuantity: true,
        rulesUnit: true,
        validStatusName: 'error',
        validStatusType: 'success',
        name: 'error',
        articlenumber: 'error',
        quantity: 'error',
        unit: 'error',

      })
    } else if (type === 'services') {
      setRules({
        rules1: false,
        rules2: false,
        rules3: true,
        rulesName: true,
        rulesArtic: false,
        rulesQuantity: true,
        rulesUnit: true,
        validStatusName: 'error',
        name: 'error',
        quantity: 'error',
        unit: 'error',
      })
    }
  }

  const onCancel = () => {
    const clearForm = {

      name: '',
      manufacturer: '',
      number: '',
      type: '',
      component: '',
      serialnumber: '',
      note: '',
      catvessel: '',
      ordertype: 'spareParts',
      item: {}
    }
    setCopyOrder(clearForm)
    form.resetFields();
    setTypeOrder('spareParts')  // положение Radio button
    settingValidRulesOrder('spareParts')
    setFileList([])  // clear file list
    showAlert && setShowAlert(false)  // присвоить false ели значение true
    window.onbeforeunload = null;
    setModalVisible(false)
  }

  const confirmClose = () => {
    Modal.confirm({
      title: 'Подтверждение',
      icon: <ExclamationCircleOutlined />,
      content: 'Все введенные данные не сохраняться',
      okText: 'все равно закрыть',
      cancelText: 'отмена',
      onOk: () => { onCancel() }
    });
  };

  const confirmSubmit = () => {
    Modal.confirm({
      title: 'Подтверждение отправки заявки',
      icon: <ExclamationCircleOutlined />,
      content: 'Все данные введены правильно?',
      okText: 'Создать заявку',
      cancelText: 'отмена',
      onOk: () => { onSubmit() }
    });
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (vals) => {
        let body = {
          name: vals.name,
          vessel: vesselCode,
          ordertype: vals.ordertype,    //тип заявки  valueType
          type: vals.type,
          serialnumber: vals.partnum,
          manufacturer: vals.vendor,
          newmanufacturer: context.vendors.find(item => item.key === vals.vendor),   //передаем нового вендора на сервер
          catvessel: vals.catvessel,
          component: vals.component,
          note: vals.note,
          rows: vals.items
        };
        console.log('fileList ', fileList)
        console.log("Going to submit request with body", { body });
        let resp = await axios.post("https://lk.transcargo-group.com/basezip/hs/application", body, { auth });
        console.log({ resp });
        console.log('new order ', resp.data.code);
        console.log('files length', fileList.length);
        //---------Отправка файла, если добавлен в fileList---------------------------------
        if (fileList.length !== 0) {
          const formData = new FormData();
          fileList.forEach((file) => {
            formData.append('files[]', file);
            console.log('file ', file);
          });
          // setUploading(true);
          console.log('formData ', formData);
          let respFile = await axios.post(`https://lk.transcargo-group.com/basezip/hs/application?id=${resp.data.orderKey}`, formData, { auth })
          console.log('respFile ', respFile);
        }
        //---------------------------------------

        //refresh orders list
        let orders = await axios.get(`https://lk.transcargo-group.com/basezip/hs/zayavki/${vesselCode}`, { auth });
        console.log({ orders });
        context.setOrders(orders.data);
        form.resetFields();
        setFileList([])  //очищаем список с вложенным файлом
        settingValidRulesOrder('spareParts')  //Валидация полей по умолчанию
        setTypeOrder('spareParts')  // положение Radio button
        window.onbeforeunload = null;  // закрывать вкладку без предупреждения
        showAlert && setShowAlert(false) // 
        setModalVisible(false) //context.setVisible(false);
        message.success(`Заявка создана успешно ${resp.data.code}`)   // оповещение пользователя
      })
      .catch((err) => {
        console.error("Validation error: ", err);
        setErrorDescription(err.response.data.Error)   //Записать текст ошибки
        setShowAlert(true)   // показать предупреждение
      });
  }

  return (
    <Modal
      title="Создать новую заявку"
      forceRender={true}
      open={modalVisible}
      // visible={context.isVisible()}
      width={1100}
      onOk={() => context.setVisible(false)}
      onCancel={confirmClose}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={confirmClose}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" onClick={confirmSubmit}>
          Создать
        </Button>,
          <div style={{ padding: 10 }}>
          {showAlert &&
            <Space
              direction="vertical"
              style={{
                width: '100%'
              }}
            >
              <Alert
                message="Ошибка"
                description={errorDescription}
                type="error"
              />
            </Space>
          }
        </div>
      ]}
    >
      <Form form={form} initialValues={copyOrder}>

        <div className="radiobtn">
          <div className="typelabel"> <Text strong>Тип заявки:</Text> </div>
          <Form.Item name="ordertype">
            <Radio.Group onChange={onChangeRadioBtn} defaultValue='' buttonStyle="solid">
              <Radio.Button value="spareParts">Запасные части</Radio.Button>
              <Radio.Button value="expendableSupply">Расходное снабжение и материалы</Radio.Button>
              <Radio.Button value="services">Услуги/проверки</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>

        <Form.Item
          label="Наименование"
          name="name"
          validateStatus={rules.validStatusName}
          rules={[
            {
              required: true,
              message: 'Отсутствует наименование'
            }]}
        >
          <Input placeholder="Введите наименование" maxLength={60} />
        </Form.Item>

        <Form.Item
          label="Тип оборудования"
          name="type"
          validateStatus={rules.validStatusType}
          rules={[
            {
              required: rules.rules1,
              message: 'Отсутствует значение'
            }]}
        >
          <Input placeholder="Введите тип оборудования" maxLength={40} />
        </Form.Item>

        <Form.Item
          label="Серийный номер"
          name="partnum"
          validateStatus={rules.validStatusSerial}
          rules={[{
            required: rules.rules1,
            message: 'Отсутствует серийный номер'
          }]}
        >
          <Input placeholder="Введите серийный номер оборудования" maxLength={20} />
        </Form.Item>

        <Form.Item
          label="Производитель"
          name="vendor"
          tooltip="Запрещаеться создавать одинаковых производителей!! Все новые производители проверяються суперинтендантом."
          validateStatus={rules.validStatusVendor}
          rules={[
            {
              required: rules.rules1,
              message: 'Отсутствует производитель'
            }]} >

          <Select placeholder="Укажите производителя"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                <Space
                  style={{
                    padding: '0 8px 4px',
                  }}
                >
                  <Input
                    placeholder="Введите новое название производителя"
                    ref={inputRef}
                    style={{
                      width: 300,
                    }}
                    value={name}
                    onChange={onNameChange}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Добавить нового производителя
                  </Button>
                </Space>
              </>
            )}
          >
            {context.vendors.map(v => <Option key={v.key} value={v.key}>{v.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          label="Компонент"
          name="component"
          validateStatus={rules.validStatusComponent}
          rules={[
            {
              required: rules.rules1,
              message: 'Отсутствует компонент'
            }]}>
          <Select placeholder="Укажите компонент">
            {context.components.map(v => <Option key={v.key} value={v.key}>{v.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          label="Категория"
          name="catvessel"
          validateStatus={rules.validStatusName}
          rules={[
            {
              required: rules.rules1,
              message: 'Укажите категорию'
            },]}
          tooltip="Для удобства планирования...">
          <Select placeholder="Укажите категорию">
            {/* {context.catvessel.map(v => <Option key={v.key} value={v.key}>{v.name}</Option>)} */}
            {(typeOrder === 'spareParts') ?
              context.spareParts.map(v => <Option key={v.key} value={v.key}>{v.name}</Option>) :
              (typeOrder === 'expendableSupply') ?
                context.expendableSupply.map(v => <Option key={v.key} value={v.key}>{v.name}</Option>) :
                context.services.map(v => <Option key={v.key} value={v.key}>{v.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          label="Примечание"
          name="note">
          <Input.TextArea placeholder="Введите пожелания" maxLength={250} />
        </Form.Item>

        <Form.Item
          label="Файл"
          name="upload"
          tooltip="Размер вложения не более 4 Мб. тип файла только pdf, jpg, png. Можно вложить только один файл."
          valuePropName='fileList'
          getValueFromEvent={(event) => {
            return event?.fileList
          }}
        >
          <Upload
            {...props}
            maxCount={1}  //можно добавить только один файл
          >
            <Button icon={<UploadOutlined />}>Вложить файл</Button>
          </Upload>
        </Form.Item>

        <Form.List name="items">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Space key={field.key} align="start">
                    <div>
                      <div className='labelName'>
                        Наименование
                      </div>
                      <Form.Item
                        {...field}
                        name={[field.name, 'name']}
                        label={[index + 1]}
                        // fieldKey={[field.fieldKey, 'name']}
                        rules={[{ required: rules.rulesName, message: 'Отсутствует название' }]}
                        validateStatus={rules.name}
                      >
                        <TextArea placeholder="Наименование" autoSize />
                      </Form.Item>
                    </div>
                    <div>
                      Код/Артикул
                      <Form.Item
                        {...field}
                        name={[field.name, 'articlenumber']}
                        // fieldKey={[field.fieldKey, 'articlenumber']}
                        rules={[{ required: rules.rulesArtic, message: 'Отсутствует артикул' }]}
                        validateStatus={rules.articlenumber}
                      >
                        <Input placeholder="Код/Артикул" />
                      </Form.Item>
                    </div>
                    <div>
                      № Чертежа
                      <Form.Item
                        {...field}
                        name={[field.name, 'drawing']}
                        // fieldKey={[field.fieldKey, 'drawing']}
                        rules={[{ required: rules.rules1, message: 'Отсутствует актирул' }]}
                        validateStatus={rules.drawing}
                      >
                        <Input placeholder="№ Чертежа" />
                      </Form.Item>
                    </div>
                    <div>
                      Позиция по чертежу
                      <Form.Item
                        {...field}
                        name={[field.name, 'position']}
                        rules={[{ required: rules.rules1, message: 'Отсутствует значение' }]}
                        validateStatus={rules.position}
                      >
                        <Input placeholder="Позиция по чертежу" />
                      </Form.Item>
                    </div>
                    <div>
                      Количество
                      <Form.Item
                        {...field}
                        name={[field.name, 'quantity']}
                        rules={[{ required: rules.rulesQuantity, message: 'Отсутствует количество' }]}
                        validateStatus={rules.quantity}
                      >
                        <Input placeholder="Количество" />
                      </Form.Item>
                    </div>
                    <div>
                      Ед. изм
                      <Form.Item
                        {...field}
                        name={[field.name, 'unit']}
                        rules={[{ required: rules.rulesUnit, message: 'Отсут. ед.изм.' }]}
                        validateStatus={rules.unit}
                      >
                        <Select placeholder="шт" style={{ width: '65px' }} >
                          {units.map(item => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> Добавить
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default NewOrder;
