/* eslint-disable react/jsx-filename-extension */
import React, {useState} from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ThemeContext from './context';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';

function Main() {
  const [orders, setOrders] = useState(null);
  const [auth, setAuth] = useState(null);
  const [vesselCode, setVesselCode] = useState(null);
  const [vesselName, setVesselName] = useState('');
  const [vendors, setVendors] = useState([]);
  const [components, setComponents] = useState([]);
  const [catvessel, setСatvessel] = useState([]);

  const [spareParts, setSpareParts] = useState([]);
  const [expendableSupply, setExpendableSupply] = useState([]);
  const [services, setServices] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [typeOrder, setTypeOrder] = useState('spareParts')
  const [copyOrder, setCopyOrder] = useState({
      component: '',
      manufacturer: '',
      name: '',
      note: '',
      notdelete: '',
      number: '',
      type: '',
      serialnumber: '',
      catvessel: '',
      ordertype: 'spareParts',
      item: ''
  });
  const [rules, setRules] = useState({
    rules1: true,
    rules2: false, 
    rules3: false, 
    rulesName: true,
    rulesArtic: true,
    rulesQuantity: true,
    rulesUnit: true,
    validStatusName: 'error', 
    validStatusType: 'error', 
    validStatusSerial: 'error', 
    validStatusVendor: 'error', 
    validStatusComponent: 'error', 
    name: 'error', 
    articlenumber: 'error', 
    drawing: 'error', 
    position: 'error', 
    quantity: 'error', 
    unit: 'error', 
  });

  return (
    <React.StrictMode>
      <ThemeContext.Provider value={{ 
       orders, setOrders, 
       auth, setAuth,
       vesselCode, setVesselCode,
       vesselName, setVesselName,
       vendors, setVendors,
       components, setComponents,
       catvessel, setСatvessel,
       spareParts, setSpareParts,
       expendableSupply, setExpendableSupply,
       services, setServices,
       copyOrder, setCopyOrder,
       modalVisible, setModalVisible,
       typeOrder, setTypeOrder,
       rules, setRules
        }}>
       <ConfigProvider locale={ru_RU}>
          <App />
        </ConfigProvider>
    </ThemeContext.Provider>
  </React.StrictMode>
  )
}

// ReactDOM.render(
//   <Main />,
//   document.getElementById('root'),
// );
const root = createRoot(document.getElementById('root'))
root.render( <Main /> )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
