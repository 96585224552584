import React, { useContext } from 'react';
import { Table, Button, Typography, Space } from 'antd';
import ThemeContext from './context';
import DownloadLink from './DownloadLink';
import ConfirmReceipt from './confirmReceipt';
import DownloadForm from './DownloadForm'
const { Text } = Typography;

function OrderDetails(resp) {

  const { setCopyOrder, setModalVisible, setTypeOrder, setRules, vendors, components,
    spareParts, expendableSupply, services } = useContext(ThemeContext)

  const columns = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
      width: 40,
    },
    {
      title: "Код/Артикул",
      dataIndex: "articlenumber",
      key: "articlenumber",
      render: (text, record) => (
        record.del === 'yes' ? <Text type="danger" delete>{text}</Text> : <Text> {text} </Text>
      )
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        record.del === 'yes' ? <Text type="danger" delete>{text}</Text> : <Text> {text} </Text>
      )
    },
    {
      title: "№ Чертежа",
      dataIndex: "drawing",
      key: "drawing",
      render: (text, record) => (
        record.del === 'yes' ? <Text type="danger" delete>{text}</Text> : <Text> {text} </Text>
      )
    },
    {
      title: "Позиция по чертежу",
      dataIndex: "position",
      key: "position",
      render: (text, record) => (
        record.del === 'yes' ? <Text type="danger" delete>{text}</Text> : <Text> {text} </Text>
      )
    },
    {
      title: "Количество",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      render: (text, record) => (
        record.del === 'yes' ? <Text type="danger" delete>{text}</Text> : <Text> {text} </Text>
      )
    },
    {
      title: "Ед. изм",
      dataIndex: "unit",
      key: "unit",
      width: 80,
      render: (text, record) => (
        record.del === 'yes' ? <Text type="danger" delete>{text}</Text> : <Text> {text} </Text>
      )
    }
  ];
  const settingValidRulesOrder = (type) => {
    if (type === 'spareParts') {
      setRules({
        rules1: true,
        rules2: false,
        rules3: false,
        rulesName: true,
        rulesArtic: true,
        rulesQuantity: true,
        rulesUnit: true,
        validStatusName: 'error',
        validStatusType: 'error',
        validStatusSerial: 'error',
        validStatusVendor: 'error',
        validStatusComponent: 'error',
        name: 'error',
        articlenumber: 'error',
        drawing: 'error',
        position: 'error',
        quantity: 'error',
        unit: 'error',
      })
    } else if (type === 'expendableSupply') {
      setRules({
        rules1: false,
        rules2: true,
        rules3: false,
        rulesName: true,
        rulesArtic: true,
        rulesQuantity: true,
        rulesUnit: true,
        validStatusName: 'error',
        validStatusType: 'success',
        name: 'error',
        articlenumber: 'error',
        quantity: 'error',
        unit: 'error',

      })
    } else if (type === 'services') {
      setRules({
        rules1: false,
        rules2: false,
        rules3: true,
        rulesName: true,
        rulesArtic: false,
        rulesQuantity: true,
        rulesUnit: true,
        validStatusName: 'error',
        name: 'error',
        quantity: 'error',
        unit: 'error',
      })
    }
  }

  const orderCopy = () => {
    const order = resp.details.data.header
    // console.log('order', order);
    // console.log('vendors- ', vendors);
    const orderRows = resp.details.data.rows
    const typeorder = resp.details.data.header.typeorder

    let vendorsId = order.manufacturer ? vendors.find(vend => vend.name === order.manufacturer).key : ''
    let componentsId = order.component ? components.find(comp => comp.name === order.component).key : ''
    let catvesselId = ''
    if (typeorder === 'spareParts') {
      catvesselId = spareParts.find(comp => comp.name === order.catvessel).key
      console.log('spareParts--', spareParts);
    } else if (typeorder === 'expendableSupply') {
      console.log('expendableSupply--', expendableSupply);
      catvesselId = expendableSupply.find(exp => exp.name === order.catvessel).key
    } else if (typeorder === 'services') {
      catvesselId = services.find(serv => serv.name === order.catvessel).key
      console.log('services--', services);
    }

    const formData = {
      name: order.name,
      type: order.type,
      partnum: order.serialnumber,
      vendor: vendorsId, //order.header.manufacturer,
      catvessel: catvesselId, //order.header.catvessel,
      component: componentsId, //order.header.component,
      note: '',//order.note,   примечание не копируем
      ordertype: order.typeorder,
      items: orderRows //order.rows
    }

    setCopyOrder(formData)
    console.log();
    setTypeOrder(typeorder) // изм типа заявки, для поля формы "тип заявки"
    settingValidRulesOrder(typeorder) //изм валидации перед открытием формы
    setModalVisible(true)
  }

  return (
    <p>
      <Space wrap>
        <Button onClick={orderCopy}>Создать копию </Button>
        <ConfirmReceipt
          number={resp.details.data.header.number}
          statuskey={resp.statuskey}
          auth={resp.auth}
          vesselCode={resp.vesselCode}
          setOrders={(o) => resp.setOrders(o)}
          setVisible={v => resp.setVisible(v)}
        />
        <DownloadLink
          dataSource={resp.details.data}
          auth={resp.auth}
        />
        <DownloadForm
        dataSource={resp.details.data}
        auth={resp.auth}/>
      </Space>
      <div className='flex-parent'>
        <div className='elem'>
          <p><Text strong>Наименование:</Text> {resp.details.data.header.name}</p>
          <p><Text strong>Тип оборудования:</Text> {resp.details.data.header.type}</p>
          <p><Text strong>Номер:</Text> {resp.details.data.header.number} <Text strong>Дата: </Text>{resp.details.data.header.date}</p>
          <p><Text strong>Компонент:</Text> {resp.details.data.header.component}</p>
        </div>
        <div className='elem'>
          <p><Text strong>Серийный номер:</Text> {resp.details.data.header.serialnumber}</p>
          <p><Text strong>Производитель:</Text> {resp.details.data.header.manufacturer}</p>
          <p><Text strong>Примечание:</Text> {resp.details.data.header.note}</p>
          <p><Text strong>Комментарий удаления:</Text> {resp.details.data.header.notedelete}</p>
        </div>
      </div>
      <Table
        dataSource={resp.details.data.rows}
        columns={columns}
        pagination={false}
        size='small'
        scroll={{
          y: 450,
        }}
      />
    </p>
  );
}

export default OrderDetails;
