/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';
import LoginForm from './LoginForm';
import Main from './Main';
import ThemeContext from './context';

function App() {
    const { orders,  setOrders, 
    auth, setAuth,
    vesselCode, setVesselCode,
    vendors, setVendors,
    components, setComponents,
    catvessel, setСatvessel,
    spareParts, setSpareParts,
    expendableSupply, setExpendableSupply,
    services, setServices } = useContext(ThemeContext)
  // const [orders, setOrders] = useState(null);
  // const [auth, setAuth] = useState(null);
  // const [vesselCode, setVesselCode] = useState(null);
  // const [vendors, setVendors] = useState([]);
  // const [components, setComponents] = useState([]);
  // const [catvessel, setСatvessel] = useState([]);
  // const [spareParts, setSpareParts] = useState([]);
  // const [expendableSupply, setExpendableSupply] = useState([]);
  // const [services, setServices] = useState([]);

  return (
    orders //&& orders.length > 0
      ? (<Main 
          orders={orders} 
          vendors={vendors} 
          components={components} 
          vesselCode={vesselCode} 
          getAuth={() => auth} 
          setOrders={(o) => setOrders(o)}
          catvessel={catvessel} 
          spareParts={spareParts}
          expendableSupply={expendableSupply}
          services={services}
          />)
      : (<LoginForm 
          setOrders={o => setOrders(o)} 
          setAuth={a => setAuth(a)} 
          setVendors={v => setVendors(v)} 
          setComponents={c => setComponents(c)} 
          setVesselCode={vc => setVesselCode(vc)} 
          setСatvessel={vv => setСatvessel(vv)} 
          setSpareParts={v => setSpareParts(v)} 
          setExpendableSupply={v => setExpendableSupply(v)} 
          setServices={v => setServices(v)} 
          />)
  );
}

export default App;
